import React from 'react';
import injectSheet from 'react-jss';
import Layout from '../components/layout';
import themeColors from '../styles/theme';
import colors from '../styles/colors';
import Translate from '../components/Translate';
import { Helmet } from 'react-helmet';

const styles = {
    container: {
        padding: '8%',
        display: 'grid',
        textAlign: 'center',
        fontWeight: 300,
        gap: '12px'
    },
    title: {
        fontSize: '26px'
    },
    message: {
        fontSize: '16px',
        lineHeight: '25px',
        colors: colors.darkui_surface_3
    },
    buttonLink: {
        padding: '8px 16px',
        backgroundColor: themeColors.primaryButtonBackground,
        color: themeColors.primaryButtonText,
        borderRadius: '4px',
        display: 'inline-block',
        fontWeight: '400',
        focus: {
            backgroundColor: themeColors.primaryButtonBackgroundFocus,
            color: themeColors.primaryButtonText
        },
        hover: {
            backgroundColor: themeColors.primaryButtonBackgroundHover,
            color: themeColors.primaryButtonText
        },
        active: {
            backgroundColor: themeColors.primaryButtonBackgroundActive,
            color: themeColors.primaryButtonText
        }
    }
};

class AccountSharing extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Layout noMinHeight={true} showFooter={false}>
                <Helmet>
                    <title>Garmin Connect</title>
                </Helmet>
                <div className={classes.container}>
                    <div className={classes.title}>
                        <Translate ns="common" content="something_went_wrong" />
                    </div>
                    <div className={classes.message}>
                        <Translate ns="account_sharing" content="unable_to_grant_access_try_again" />
                    </div>
                    <div>
                        <a href="/mobile-link/gcm" className={classes.buttonLink}>
                            <Translate ns="common" content="open_connect_app" />
                        </a>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(AccountSharing);
